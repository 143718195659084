import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import FlexSection from "../generic/layout/flex-section"
import LoaderButton from "../generic/loader-button"
import CompactLoader from "../generic/loaders/compact-loader"
import WishlistIcon from "../../images/icons/wishlist"
import Counter from "./counter"

import CartContext from "../../context/cart/cart-context"
import { navigate } from "gatsby"

const ProductQuantityRow = ({ productId, addToCartAnalyticsEvent = () => { } }) => {
  // Perform graphql query
  const wishlistEnabledQueryData = useStaticQuery(WISHLIST_ENABLED_QUERY)
  const wishlistEnabled =
    wishlistEnabledQueryData?.seaSiteInfo?.site_feature_flags?.enable_wishlist

  const {
    cartReady,
    addToCart,
    openCartDrawer,
    addToWishlist,
    removeFromWishlist,
    wishlistContents,
  } = useContext(CartContext)

  // This state is used to keep track of the quantity
  const [quantity, setQuantity] = useState(1)
  // This forces the Counter component to update it's value
  const [quantityForceSetComponentValue, setQuantityForceSetComponentValue] =
    useState(1)
  // This forces the Counter component to update it's value
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [apiError, setApiError] = useState("")

  const handleAddToCart = () => {
    setAddToCartLoading(true)
    addToCartAnalyticsEvent(quantity)
    addToCart({ id: productId, quantity })
      .then(() => {
        setApiError("")
        setAddToCartLoading(false)
        openCartDrawer(true)
      })
      .catch(async (error) => {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
          console.log("Error Response: ", errorString)
        } else {
          console.log("Error Response: ", error)
        }
        setApiError(errorString)
        setAddToCartLoading(false)
      })
  }

  // Convenience variable to chech if current item is wishlisted
  const isWishlisted =
    wishlistContents &&
    wishlistContents.length &&
    wishlistContents.some((item) => item.product_id == productId)

  // Handle clicking on the wishlist button, either adding or removing from wishlist
  const handleWishlistButton = () => {
    // If on of these calls fail, the user is probably not logged in
    if (isWishlisted) {
      removeFromWishlist(productId)
    } else {
      addToWishlist(productId).catch((err) => {
        if (
          err &&
          err.message &&
          err.message.includes("need to be logged in")
        ) {
          navigate("/login/", {
            state: {
              fromUrl: window.location.pathname,
              showNotice: "You need to be logged in to use your wishlist",
            },
          })
        }
      })
    }
  }

  return (
    <>
      <FlexSectionStyling direction="row" margin="0 0 1rem">
        <StandardQuantitySection direction="row">
          Quantity
          <Counter min={1} stepSize={1} onChange={setQuantity} />
        </StandardQuantitySection>
        <StyledLoadingSubmitButton
          className="overwrite-width"
          text={"Add to cart"}
          loading={addToCartLoading}
          loaderColour="white"
          onClick={handleAddToCart}
          loaderHeight="19px"
          padding="0"
        />
        {wishlistEnabled && (
          <WishlistLinkStyling
            onClick={handleWishlistButton}
            active={isWishlisted}
          >
            <WishlistButtonGridStyling>
              {cartReady ? (
                <>
                  <WishlistIcon />
                  <WishListButtonStyling>
                    {isWishlisted ? "Remove from wishlist" : "Add to wishlist"}
                  </WishListButtonStyling>
                </>
              ) : (
                <CompactLoader height="51px" />
              )}
            </WishlistButtonGridStyling>
          </WishlistLinkStyling>
        )}
        {!apiError ? null : (
          <ErrorMessage dangerouslySetInnerHTML={{ __html: apiError }} />
        )}
      </FlexSectionStyling>
    </>
  )
}

export default ProductQuantityRow

// ===============
//     STYLES
// ===============

const StyledLoadingSubmitButton = styled(LoaderButton)`
  &.overwrite-width {
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      width: 50%;
      margin: auto;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      width: 100%;
    }
  }
`

const ErrorMessage = styled.div`
  width: 100%;
  padding-top: 5px;
  color: ${({ theme }) => theme.colors.danger.dark};
`

const FlexSectionStyling = styled(FlexSection)`
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    flex-direction: row;
    flex-wrap: wrap;
    > a {
      width: fit-content !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 0;
    button {
      width: fit-content;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    .wishlist {
      padding-left: 0;
    }
  }
`
const SqareMQuantitySectionColumnStyling = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
  }
`
const SquareMQuantitySectionStyling = styled(FlexSection)`
  margin: 0;
  width: 100%;
  line-height: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    flex-wrap: no-wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 0.313rem;
  }
`
const StandardQuantitySection = styled(FlexSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 0;
  width: fit-content;
  line-height: 53px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
    flex-wrap: no-wrap;
    width: 100%;
    justify-content: center;
    margin: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 0.313rem;
  }
`
const WishlistLinkStyling = styled.div`
  padding-left: 1.25rem;
  cursor: pointer;
  color: #818181;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.blue};
      ${({ theme, active }) => active && `fill: ${theme.colors.blue}`};
    }
  }
  &:hover svg {
    path {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding-left: 0;
  }

  margin: auto;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
  }
`
const WishlistButtonGridStyling = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  > a:first-child {
    height: 30px;
  }
`
const WishListButtonStyling = styled.button`
  padding: 1rem 0 1rem 0.75rem !important;
  border: none !important;
  background: white;
  color: black;
  &:hover,
  &.button-active {
    background-color: white !important;
    color: ${({ theme }) => theme.colors.blue} !important;
  }
`

// Graphql query for all products
export const WISHLIST_ENABLED_QUERY = graphql`
  {
    seaSiteInfo {
      site_feature_flags {
        enable_wishlist
      }
    }
  }
`
