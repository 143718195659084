import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import FlexSection from "../generic/layout/flex-section"

import FacebookIcon from "../../images/icons/facebook"
import TwitterIcon from "../../images/icons/twitter"
import LinkedInIcon from "../../images/icons/linkedin"
import MailIcon from "../../images/icons/solidmail"
import WhatsAppIcon from "../../images/icons/whatsapp"

const ShareButtons = ({ sharingUrl, type = "Page" }) => {
  const siteInfoData = useStaticQuery(SITE_INFO_QUERY)
  const siteTitle = siteInfoData.seaSiteInfo.site_title

  const socialButtons = [
    {
      Icon: TwitterIcon,
      generateLink: (url) => `https://twitter.com/intent/tweet?text=${url}`,
    },
    {
      Icon: FacebookIcon,
      generateLink: (url) =>
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      Icon: LinkedInIcon,
      generateLink: (url) =>
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
    },
    {
      Icon: MailIcon,
      generateLink: (url, type) =>
        `mailto:?subject=I wanted you to see this ${siteTitle} ${type}&body=Check out this ${siteTitle} ${type} ${url}`,
    },
    {
      Icon: WhatsAppIcon,
      generateLink: (url, type) =>
        `https://wa.me/?text=Check out this ${siteTitle} ${type} ${url}`,
    },
  ]
  
  return (
    <SharebuttonFlexStyling direction="row" margin={"0 0 0"}>
      {socialButtons.map(({ Icon, generateLink }, i) => (
        <SocialShareButtonsLinkStyling
          key={i}
          href={generateLink(encodeURIComponent(sharingUrl), type)}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Icon fill="black" />
        </SocialShareButtonsLinkStyling>
      ))}
    </SharebuttonFlexStyling>
  )
}

// ===============
//     STYLES
// ===============
const SocialShareButtonsLinkStyling = styled.a`
  display: block;
  margin-right: 30px;
  margin-top: 20px;
  svg,
  svg path {
    vertical-align: bottom;
  }
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    &:last-child {
      display: none;
    }
  }
`
const SharebuttonFlexStyling = styled(FlexSection)`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }
`
export default ShareButtons

// ===============
//      QUERY
// ===============
const SITE_INFO_QUERY = graphql`
  {
    seaSiteInfo {
      site_title
    }
  }
`
