import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../../theme/layout"
import SEO from "../../components/header/seo"
import FlexSection from "../../components/generic/layout/flex-section"
import DividerLine from "../../components/generic/divider-line"
import ProductImageSection from "../../components/product-page/product-image-section"
import ProductQuantityRow from "../../components/product-page/product-quantity-row"

// import ProductBrandRow from "../../components/product-page/product-brand-row"
import ProductStarRating from "../../components/products/product-star-rating"
// import ProductPriceRow from "../../components/product-page/product-price-row"
import ProductVarationSelect from "../../components/products/product-variation-select"
import ShareButtons from "../../components/generic/share-buttons"
import ProductTabsSection from "../../components/product-page/product-tabs-section"
import ProductRelatedSection from "../../components/product-page/product-related-section"

import { useQueryParam, NumberParam } from "use-query-params"
import { calculateDiscount, formatPrice } from "../../lib/format"

import { analyticsAddToCart, analyticsSelect } from "../../services/google-analytics"
import { pixelAddToCart, pixelSelect } from "../../services/facebook-pixel"

const ProductPage = ({ pageContext, data, location }) => {
  const parentProduct = data.seaProduct
  const variations = parentProduct?.variations || []

  // selectedVariation is either a variation product or simple product's data
  const [selectedQueryParam, setSelectedQueryparam] = useQueryParam(
    "select",
    NumberParam
  )
  const [selectedVariation, setSelectedVariation] = useState(() => {
    if (selectedQueryParam) {
      // Find selected variation and preselect it
      const matchingVariation = variations.find(
        (vr) => vr?.database_id == selectedQueryParam
      )
      if (matchingVariation) {
        return matchingVariation
      }
    } else if (variations.length > 0) {
      // Otherwise if we have variations, preselect the first on
      return variations[0]
    }

    // Not a variable product, selectedVariation should just contain product data
    return parentProduct
  })

  // On load, trigger analytics for view product
  useEffect(() => {
    const currentProduct = selectedVariation || parentProduct
    if (currentProduct) {
      analyticsSelect(currentProduct)
      pixelSelect(currentProduct)
    }
  }, [selectedVariation, parentProduct])

  const addToCartAnalyticsEvent = (quantity) => {
    const currentProduct = selectedVariation || parentProduct
    if (currentProduct) {
      analyticsAddToCart(currentProduct, quantity)
      pixelAddToCart(currentProduct)
    }
  }

  // If a different variation is selected in the dropdown, update URL and update state
  const changeVariation = (newVariation) => {
    setSelectedVariation(newVariation)
    setSelectedQueryparam(newVariation.database_id, "replaceIn") // replaceIn means don't add new URL to back button
  }

  // Prepare sharing URL
  let siteUrlSharing = ""
  if (typeof window != "undefined" && window.location) {
    siteUrlSharing = window.location.href
  }

  // Convenience variables
  const title = selectedVariation?.title || parentProduct?.title || ""
  const description =
    selectedVariation?.description || parentProduct?.description || ""
  const productId = selectedVariation?.database_id || parentProduct?.database_id
  // Prepare price and discount values
  const price = selectedVariation?.price || ""
  const regularPrice = selectedVariation?.regularPrice || ""
  const productRating = selectedVariation?.review_average_rating || 0
  const productReviews = selectedVariation?.reviews || []
  const discount = calculateDiscount(price, regularPrice)
  const stockStatus = ""
  const yoast = selectedVariation?.seo || parentProduct?.seo

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={title} description={""} yoast={yoast} />
      <StyledFlexSection direction="row">
        <ProductImageSection
          product={parentProduct}
          selectedVariation={selectedVariation}
          productTitle={title}
        />
        <InfoSectionStyling>
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          <BrandSectionStyling>
            <h6 dangerouslySetInnerHTML={{ __html: parentProduct.brand }} />
          </BrandSectionStyling>
          <ProductStarRating
            rating={productRating}
            display="block"
            margin="0"
          />
          <PriceRowContainer>
            {!discount ? null : (
              <span
                dangerouslySetInnerHTML={{ __html: formatPrice(regularPrice) }}
                className="regular-price"
              />
            )}
            <span
              dangerouslySetInnerHTML={{ __html: formatPrice(price) }}
              className="sale-price"
            />
            <span className="vat-text">(incl. VAT)</span>
          </PriceRowContainer>
          {stockStatus && (
            <SpanHeadingStyling>
              Availability:
              {stockStatus == "instock" && (
                <SpanInStockStyling>In Stock</SpanInStockStyling>
              )}
              {stockStatus == "outofstock" && (
                <SpanOutOfStockStyling>Out of Stock</SpanOutOfStockStyling>
              )}
              {stockStatus == "onbackorder" && (
                <SpanOutOfStockStyling>On Backorder</SpanOutOfStockStyling>
              )}
            </SpanHeadingStyling>
          )}
          <SpanHeadingStyling>
            Shipping Estimate:
            <SpanShippingStyling>
              {pageContext.shipping_estimate}
            </SpanShippingStyling>
          </SpanHeadingStyling>
          {variations.length > 1 && (
            <ProductVarationSelect
              setSelectedVariation={changeVariation}
              selectedVariation={selectedVariation}
              variations={variations}
            />
          )}
          <ProductQuantityRow productId={productId} addToCartAnalyticsEvent={addToCartAnalyticsEvent} />
          <DividerLine margin="1.875rem 0" />
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="description"
          />
          <ShareButtons sharingUrl={siteUrlSharing} type={"Product"} />
        </InfoSectionStyling>
      </StyledFlexSection>
      <ProductTabsSection
        product={selectedVariation}
        productId={productId}
        productReviews={productReviews}
      />
      <ProductRelatedSection relatedProducts={[]} />
    </Layout>
  )
}

export default ProductPage

// ===============
//     STYLES
// ===============
const StyledFlexSection = styled(FlexSection)`
  margin-top: 20px;
`
const InfoSectionStyling = styled.div`
  margin-left: 3.125rem;
  width: 50%;
  h3 {
    margin-bottom: 0;
  }
  p {
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      min-height: auto;
    }
  }
  .product-rating {
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    margin-left: 0;
  }
`
const BrandSectionStyling = styled.div`
  h6 {
    color: ${({ theme }) => theme.colors.lightgrey5};
    text-transform: capitalize;
    display: inline-block;
  }
`
const PriceRowContainer = styled.div`
  span {
    font-size: 1.313rem;
  }
  .regular-price {
    color: ${({ theme }) => theme.colors.grey};
    text-decoration: line-through;
    margin-right: 1rem;
  }
  .sale-price {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  .vat-text {
    font-size: 0.75rem;
  }
`
const SpanHeadingStyling = styled.span`
  margin-top: 0.3rem;
  display: block;
`
const SpanInStockStyling = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.success.light};
`
const SpanOutOfStockStyling = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.danger.light};
`
const SpanShippingStyling = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`

// Graphql query for all products
export const query = graphql`
  query ($database_id: Int!) {
    seaProduct(database_id: { eq: $database_id }) {
      database_id
      title
      description
      brand
      price
      regular_price
      review_average_rating
      reviews {
        author
        content
        date
        rating
        date_gmt
      }
      featured_image {
        sourceUrl
      }
      images {
        sourceUrl
      }
      product_page_tabs {
        tab_name
        tab_contents {
          tab_fields {
            row_name
            row_value
          }
        }
      }
      categories {
        title
      }
      variations {
        variation_name
        database_id
        title
        description
        price
        regular_price
        review_average_rating
        reviews {
          author
          content
          date
          rating
          date_gmt
        }
        featured_image {
          sourceUrl
        }
        attributes {
          key
          values
        }
        seo {
          description
          og_description
          og_image
          og_title
          og_type
          site_name
          title
          twitter_card
          twitter_creator
          twitter_description
          twitter_title
        }
      }
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
