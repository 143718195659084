import React from "react"
import styled from "styled-components"

import DividerLine from "../../generic/divider-line"

const TabContentLayout = ({ children }) => {
  return (
    <>
      <DividerLineStyling margin="0 0 3.125rem" />
      {children}
      <DividerLineStyling margin="3.125rem 0" />
    </>
  )
}

export default TabContentLayout

// ===============
//     STYLES
// ===============
const DividerLineStyling = styled(DividerLine)`
  width: 100%;
`
