import React, { useContext, useState } from "react"
import styled from "styled-components"
import Reaptcha from "reaptcha"

import AuthContext from "../../context/auth/auth-context"
import { getUser } from "../../context/auth/auth-functions"
import { postReview } from "../../api/review"

import EllipsisLoader from "../generic/loaders/elipsis-loader"
import FlexSection from "../generic/layout/flex-section"
import FormInputField from "../generic/form-input-field"
import ProductStarRating from "../products/product-star-rating"

const ReviewForm = ({ productId }) => {
  const { loggedInState, performLogout } = useContext(AuthContext)

  // Initialise states
  const [selectedRating, setSelectedRating] = useState(0)
  const [captchaPassed, setCaptchaPassed] = useState(false)
  const [author, setAuthor] = useState("")
  const [email, setEmail] = useState("")
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  // Clear all states
  const clearContent = () => {
    setAuthor("")
    setEmail("")
    setContent("")
    setSelectedRating(0)
  }

  // Handle review submission
  const handleSubmit = () => {
    // Field validation
    if (!author) {
      return setApiError("Please enter a valid name")
    }
    if (!loggedInState) {
      if (!email) {
        return setApiError("Please enter an email address")
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        return setApiError("Please enter a valid email address")
      }
    }
    if (!content) {
      return setApiError(
        "Please type a short comment of your experience using the product"
      )
    }
    if (!selectedRating) {
      return setApiError("Please pick a star rating for the product")
    }

    if (!loggedInState && !captchaPassed) {
      return setApiError(
        "Please complete the reCaptcha checkbox before continuing"
      )
    }

    // Prepare error fields and start loader
    setApiError("")
    setSuccessMessage("")
    setLoading(true)

    // Post the review
    const { token } = getUser()
    postReview(
      {
        id: productId,
        author,
        author_email: email,
        content,
        rating: selectedRating,
      },
      token,
      performLogout
    )
      .then((res) => {
        setLoading(false)
        clearContent()
        console.log("Review response: ", res)
        if (res && res.message) {
          setSuccessMessage(res.message)
        } else {
          setSuccessMessage(
            "Thank you for your review! Once it has been moderated, it will appear here"
          )
        }
      })
      .catch(async (err) => {
        setLoading(false)
        console.log("Request failed: ", err)
        if (err.message) setApiError(err.message)
        else
          setApiError(
            "Oh no, something went wrong while submitting your review. Our team has been notified and will look into it ASAP"
          )
        setLoading(false)
      })
  }

  // While loading, show loader
  if (loading) {
    return <EllipsisLoader />
  }

  return (
    <ReviewFormSectionStyling>
      {successMessage && <div className="message-good">{successMessage}</div>}
      {!successMessage && (
        <>
          <h3>Leave a Review</h3>
          <p>Your email address will not be published, but your name will.</p>
          <div className="form-body">
            <FlexSectionStyling>
              <ColumnSection>
                <FormInputField
                  className="short-width-input"
                  labelName="Name"
                  fieldType="text"
                  onChangeCallback={(e) => setAuthor(e.target.value)}
                />
                {loggedInState === false && (
                  <>
                    <FormInputField
                      className="short-width-input"
                      labelName="Email"
                      fieldType="text"
                      onChangeCallback={(e) => setEmail(e.target.value)}
                    />
                    <Reaptcha
                      sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
                      onVerify={() => {
                        setCaptchaPassed(true)
                      }}
                      size="normal"
                    />
                  </>
                )}
              </ColumnSection>
              <RatingSection className="short-width-input rating-section">
                <h6>
                  Your Rating <span>*</span>
                </h6>
                <p>Select a stars rating</p>
                <ProductStarRating
                  rating={selectedRating}
                  changeRating={(rat) => setSelectedRating(rat)}
                  clickable
                />
              </RatingSection>
              <FormInputField
                labelName="Content"
                fieldType="textarea"
                onChangeCallback={(e) => setContent(e.target.value)}
              />
            </FlexSectionStyling>
            {apiError && (
              <FormSubmitMessageStyling>{apiError}</FormSubmitMessageStyling>
            )}
            <div>
              <button type="submit" onClick={handleSubmit}>
                Send Review
              </button>
            </div>
          </div>
        </>
      )}
    </ReviewFormSectionStyling>
  )
}

export default ReviewForm

// ===============
//     STYLES
// ===============
const ReviewFormSectionStyling = styled.div`
  margin: 0 auto;
  width: 100%;
  h6 {
    font-size: 15px;
  }
  .short-width-input input {
    display: block;
  }
  label {
    max-width: 735px;
    width: 100%;
    display: inline-block;
    margin-top: 40px;
  }
  .short-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  .form-body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: min-content;
  }
  .form-body > div button {
    margin-top: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin: 6rem auto 0;
    label {
      max-width: 500px;
    }
    .short-width-input {
      max-width: 350px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    label {
      max-width: 350px;
      margin-left: 20px;
    }
    .short-width-input {
      max-width: 300px;
      margin-left: 0;
    }
    .rating-section {
      margin-left: 20px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .short-width-input {
      width: 100%;
      max-width: 100%;
    }
    .form-body > div {
      width: 100%;
      max-width: 100%;
    }
    .form-body > div button {
      width: 100%;
    }
    label {
      max-width: 100%;
      margin-left: 0;
    }
    .rating-section {
      margin-left: 0;
    }
  }
`
const FlexSectionStyling = styled(FlexSection)`
  height: fit-content;
  max-height: 400px;
  margin-bottom: 0;
  .g-recaptcha {
    margin-top: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    height: auto;
    max-height: none;
    .g-recaptcha {
      order: 5;
      max-width: 100%;
    }
  }
`
const ColumnSection = styled.div``
const RatingSection = styled.div`
  max-width: 735px;
  width: 100%;
  margin-top: 40px;
  margin-left: 20px;
  p {
    font-size: 0.75rem;
    font-style: italic;
  }
  > div {
    margin: 10px 0 5px;
    svg {
      width: 33px;
      height: 31px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 500px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    max-width: 350px;
    margin-left: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    margin-left: 0;
  }
`
const FormSubmitMessageStyling = styled.div`
  padding-top: 25px;
  font-size: 18px;
  width: 100%;
  color: ${({ theme }) => theme.colors.danger.dark};
`
