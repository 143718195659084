import React from "react"
import styled from "styled-components"

import { getProperty } from "../../../lib/utils"

const SpecsTabContent = ({ content = [] }) => {
  if (content && content?.length > 0) {
    return (
      <TableStyling>
        <tbody>
          {content.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{item?.tab_fields?.row_name}</td>
                <td>{item?.tab_fields?.row_value}</td>
              </tr>
            )
          })}
        </tbody>
      </TableStyling>
    )
  }

  // Content is empty
  return null
}

export default SpecsTabContent

// ===============
//     STYLES
// ===============
const TableStyling = styled.table`
  margin: 0 1.875rem;
  width: calc(100% - 3.75rem);
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 0;
    width: 100%;
  }
  border: none;
  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.lightgrey2};
  }
  td {
    border: 1px solid ${({ theme }) => theme.colors.lightgrey3};
    font-weight: normal;
    text-align: left;
    padding: 0.75rem 1rem;
  }
  tr td:first-child {
    width: 200px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 120px;
    }
  }
  .colour-value {
    text-transform: capitalize;
  }
`
