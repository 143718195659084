import React from "react"
import styled from "styled-components"

import ProductCard from "../products/product-card"
import FlexSection from "../generic/layout/flex-section"

const ProductList = ({
  products,
  className = "",
  columnNumber = 4,
  margin = "0 auto 6rem",
}) => {
  return (
    <ProductListFlexStyling
      className={className}
      direction="row"
      margin={margin}
    >
      {products.map((product, productIndex) => {
        return (
          <ProductCardStyling
            key={productIndex}
            columnNumber={columnNumber < 2 ? 2 : columnNumber}
          >
            <ProductCard product={product} className={className} width="100%" />
          </ProductCardStyling>
        )
      })}
    </ProductListFlexStyling>
  )
}

export default ProductList

// ===============
//     STYLES
// ===============
const ProductListFlexStyling = styled(FlexSection)`
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: row;
  }
`

const ProductCardStyling = styled.div`
  width: calc(
    calc(
        100% -
          ${({ columnNumber }) => {
            return 10 * (columnNumber - 1)
          }}px
      ) / ${({ columnNumber }) => columnNumber}
  );
  overflow: hidden;
  margin-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.breakLarge}) {
    :not(:nth-child(${({ columnNumber }) => columnNumber}n
          + ${({ columnNumber }) => columnNumber})) {
      margin-right: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: calc(
      calc(
          100% -
            ${({ columnNumber }) => {
              return 10 * (columnNumber - 2)
            }}px
        ) / ${({ columnNumber }) => columnNumber - 1}
    );
    :not(:nth-child(${({ columnNumber }) => columnNumber - 1}n
          + ${({ columnNumber }) => columnNumber - 1})) {
      margin-right: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: calc(calc(100% - calc(10px * 1)) / 2);
    :not(:nth-child(${({ columnNumber }) => columnNumber - 1}n
          + ${({ columnNumber }) => columnNumber - 1})) {
      margin-right: 0;
    }
    :not(:nth-child(2n + 2)) {
      margin-right: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: calc(calc(100% - calc(10px * 1)) / 2);
    :not(:nth-child(${({ columnNumber }) => columnNumber - 1}n
          + ${({ columnNumber }) => columnNumber - 1})) {
      margin-right: 0;
    }
    :not(:nth-child(2n + 2)) {
      margin-right: 10px;
    }
  }
`
