import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ImageGallery from "react-image-gallery"
import MagnifyImage from "../generic/magnify-image"

import { getProperty } from "../../lib/utils"
import { calculateDiscount } from "../../lib/format"

import "react-image-gallery/styles/css/image-gallery.css"
import CloudflareImage from "../reusable/cloudflare-image"

const ProductImageSection = ({ product, productTitle, selectedVariation }) => {
  const placeholderImgData = useStaticQuery(
    PRODUCT_PAGE_PLACEHOLDER_IMAGE_QUERY
  )
  const placeholderImgUrl = placeholderImgData?.file?.publicURL
  const variations = getProperty(product, ["variations", "nodes"], [])

  const discount = calculateDiscount(product?.price, product?.regular_price)

  // Main featured image
  const rawFeaturedImage =
    product?.featured_image?.sourceUrl || placeholderImgUrl
  let featuredImage = []
  if (rawFeaturedImage) {
    featuredImage = [
      {
        original: rawFeaturedImage,
        thumbnail: rawFeaturedImage,
        renderItem: () => (
          <MagnifyImage featuredImage={rawFeaturedImage}></MagnifyImage>
        ),
        renderThumbInner: () => (
          <GalleryThumbnailStyled
            alt="Gallery thumbnail"
            src={rawFeaturedImage}
          />
        )
      },
    ]
  }

  // Gallery images
  const galleryImages = getProperty(product, ["images"], []).map(
    (image) =>
      image.sourceUrl && {
        original: image.sourceUrl,
        thumbnail: image.sourceUrl,
        renderItem: () => (
          <MagnifyImage featuredImage={image.sourceUrl}></MagnifyImage>
        ),
        renderThumbInner: () => (
          <GalleryThumbnailStyled
            alt="Gallery thumbnail"
            src={image.sourceUrl}
          />
        ),
      }
  )

  // Variation images
  const variationImages = variations
    .map(
      (vr) =>
        vr?.image?.sourceUrl && {
          original: vr?.image?.sourceUrl,
          thumbnail: vr?.image?.sourceUrl,
          renderItem: () => (
            <MagnifyImage featuredImage={vr?.image?.sourceUrl}></MagnifyImage>
          ),
          renderThumbInner: () => (
            <GalleryThumbnailStyled
              alt="Gallery thumbnail"
              src={vr?.image?.sourceUrl}
            />
          ),
        }
    )
    .filter((i) => !!i)

  /*
  Creating a gallery array to use in image carousel. 
  First add the featured image to the array, then the variation images, then the gallery images, .
  A single item in the carousel component has properties "original" and "thumbnail" which requires src paths.
  "original" is the main image shown in the carousel, and "thumbnail" is the image shown in the scrolling slider.
  Each item in the array needs a "renderItem" field which contains the imported "MagnifyImage" component. This
  overwrites the "react-image-gallery" default render item
  */
  const imageGallery = [...featuredImage, ...variationImages, ...galleryImages]

  // Find selected variation's image and select the image
  let selectedVariationImageIndex = 0
  imageGallery.forEach((im, indx) => {
    if (im?.original == selectedVariation?.image?.sourceUrl) {
      selectedVariationImageIndex = indx
    }
  })

  return (
    <>
      <GalleryContainer>
        {!discount ? null : (
          <DiscountStyling>{`-${discount}%`}</DiscountStyling>
        )}
        <ImageGallery
          className="image-gallery"
          items={imageGallery}
          startIndex={selectedVariationImageIndex}
          showFullscreenButton={false}
          showPlayButton={false}
          lazyLoad={true}
        />
      </GalleryContainer>
    </>
  )
}

export default ProductImageSection

// ===============
//     STYLES
// ===============
const GalleryContainer = styled.div`
  max-width: 600px;
  width: auto;
  .image-gallery-content {
    position: relative;
  }
  .cloudflare-image-wrapper {
    height: unset;
  }
  .image-gallery-slide-wrapper {
    position: unset;
    outline: none;
  }
  .image-gallery-slide {
    outline: none;
    min-width: 600px;
    .cloudflare-image-img {
      object-fit: cover;
      width: 600px;
      height: 600px;
      @media (max-width: ${({ theme }) => theme.breakLarge}) {
        width: 512px;
        height: 512px;
      }
      @media (max-width: ${({ theme }) => theme.breakMedium}) {
        width: 600px;
        height: 600px;
      }
      @media (max-width: ${({ theme }) => theme.breakTiny}) {
        width: 375px;
        height: 375px;
      }
      @media (max-width: ${({ theme }) => theme.breakXTiny}) {
        width: 325px;
        height: 325px;
      }
    }
  }
  .image-gallery-thumbnails {
    overflow: hidden;
    padding: 11px 0 0;
  }
  .image-gallery-thumbnails-wrapper {
    max-width: 600px;
    padding: 0 30px;
    margin: 0 auto;
  }

  .image-gallery-thumbnail-inner {
    width: 100px;
    height: 80px;
  }
  .image-gallery-thumbnail {
    padding: 0;
    overflow: hidden;
    border-radius: 0;
  }
  .image-gallery-thumbnail-image {
    width: 100px;
    height: 80px;
    margin: 0;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.blue};
  }

  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin: 0 0 0 4px;
  }
  .image-gallery-icon {
    width: 20px;

    padding: 0;

    position: absolute;
    bottom: 0;
    border: none;
    z-index: 5;
    filter: none;
    color: ${({ theme }) => theme.colors.blue};
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .image-gallery-right-nav {
    right: 0;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    top: unset;
  }

  .image-gallery-icon:hover {
    background-color: white;
  }

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 50%;
    .image-gallery {
      width: 100%;
    }
    .image-gallery-swipe {
      height: auto;
    }
    .image-gallery-slides {
      height: auto;
    }
    .image-gallery-slide {
      min-width: 100%;
    }
    .image-gallery-image {
      width: 100%;
      height: 340px;
      margin-bottom: 0;
    }
    .image-gallery-thumbnails-wrapper {
      max-width: 100%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    max-width: 600px;
    margin: auto auto 3rem;
    .image-gallery-image {
      max-height: 450px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    .image-gallery-image {
      max-height: 75vw;
    }
  }
`
const DiscountStyling = styled.div`
  position: absolute;
  right: calc(50% + 20px);
  z-index: 50;
  background-color: #ffcb00;
  height: 60px;
  width: 120px;
  color: #1c1c1c;
  font-size: 1.313rem;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    right: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    right: calc(50% - 300px);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    right: calc(50% - 300px);
  }
  @media (max-width: calc(${({ theme }) => theme.breakTiny} + 170px)) {
    right: 25px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    height: 65px;
    width: 110px;
    line-height: 65px;
  }
`

const GalleryThumbnailStyled = styled(CloudflareImage)`

  .cloudflare-image-img {
    object-fit: contain;
    width: 100px;
    height: 80px;
  }
`
// ===============
//      QUERY
// ===============
const PRODUCT_PAGE_PLACEHOLDER_IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "placeholder.png" }) {
      publicURL
    }
  }
`
