import React from "react"
import styled from "styled-components"

import Tabs from "../generic/layout/tabs"
import TabContentLayout from "./tab-content/tab-content-layout"
import SpecsTabContent from "./tab-content/specs-tab-content"
import ProductReviewsTabContent from "./tab-content/product-reviews-tab-content"

import { getProperty } from "../../lib/utils"

const ProductTabsSection = ({ product, productId, productReviews = [] }) => {
  const tabData = getProperty(product, ["product_page_tabs"], [])
  const tabsContent = tabData
    .map((tab) => {
      if (tab && tab?.tab_name) {
        return {
          tabHeading: tab?.tab_name,
          tabContent: (
            <TabContentLayout>
              <SpecsTabContent content={tab?.tab_contents} />
            </TabContentLayout>
          ),
        }
      }
    })
    .filter((i) => !!i)

  // Add Reviews tab as last one
  tabsContent.push({
    tabHeading: "Reviews",
    tabContent: (
      <TabContentLayout>
        <ProductReviewsTabContent
          productId={productId}
          productReviews={productReviews}
        />
      </TabContentLayout>
    ),
  })

  return <StyledTabs tabsContentArray={tabsContent} />
}

export default ProductTabsSection

// ===============
//     STYLES
// ===============
const StyledTabs = styled(Tabs)`
  .tab-nav-wrapper {
    margin: 0 1.875rem;
    padding-bottom: 20px;
    justify-content: flex-start;
    font-size: 1.75rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grey2};
    line-height: 2.5;
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: 0;
    }
    > a {
      width: 50%;
      font-size: 1.75rem;
      line-height: 2.6rem;
      letter-spacing: 0px;
      @media (max-width: ${({ theme }) => theme.breakMedium}) {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }
    }
    .tab-active {
      color: ${({ theme }) => theme.colors.brandPrimary};
    }
  }
  div.tab-active {
    flex-wrap: wrap;
  }
  .gatsby-image-wrapper {
    margin: 0 1.875rem;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: 0;
    }
  }
`
