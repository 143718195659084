import React from "react"

const Mail = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="18.993"
    viewBox="0 0 23.741 18.993"
  >
    <path
      id="Icon_material-mail"
      data-name="Icon material-mail"
      d="M24.367,6H5.374A2.371,2.371,0,0,0,3.012,8.374L3,22.619a2.381,2.381,0,0,0,2.374,2.374H24.367a2.381,2.381,0,0,0,2.374-2.374V8.374A2.381,2.381,0,0,0,24.367,6Zm0,4.748-9.5,5.935-9.5-5.935V8.374l9.5,5.935,9.5-5.935Z"
      transform="translate(-3 -6)"
      fill={fill || "#003A8D"}
    />
  </svg>
)

export default Mail
